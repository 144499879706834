import { BrowserTracing } from '@sentry/browser';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { createRoutesFromChildren, matchRoutes, Routes, useLocation, useNavigationType } from 'react-router-dom';

export const init = (sessionReplay: Date | undefined) => {
  const config = {
    dsn: window.uiConfig?.monitoring?.loggerDsn,
    environment: window.uiConfig?.monitoring?.envType || 'dev',
    tracesSampleRate: 0.1,
    replaysOnErrorSampleRate: 0,
    replaysSessionSampleRate: 0,
  };

  const integrations = [];
  if (sessionReplay && new Date(sessionReplay).getTime() > Date.now()) {
    config.replaysOnErrorSampleRate = 1.0;
    config.replaysSessionSampleRate = 1.0;
    integrations.push(
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
        networkDetailAllowUrls: [window.location.origin],
      }),
    );
  }

  integrations.push(
    new BrowserTracing({
      beforeNavigate: (context) => {
        return {
          ...context,
          name: location.pathname.replace(/\/[a-f0-9]{32}/g, '/<hash>').replace(/\/\d+/g, '/<digits>'),
        };
      },
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
  );
  Sentry.init({
    ...config,
    integrations,
  });
};

export const addTag = (key: string, value: string) => {
  Sentry.setTag(key, value);
};

export const routMonitoring = () => {
  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
  return SentryRoutes;
};

export const captureException = (err: Error, additionalInfo?: unknown) => {
  Sentry.withScope((scope) => {
    if (additionalInfo) {
      scope.setExtra('additional_info', additionalInfo);
    }
    Sentry.captureException(err);
  });
};

export const captureMessage = (msg: string) => {
  Sentry.captureMessage(msg, 'info');
};
