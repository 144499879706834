import { eventFactory } from '../utils/monitoring/event-factory';
import { sendEvent } from '../utils/monitoring/send-bi';

const SERVICE_WORKER_PATH = '/verify/app/assets/service-worker.js';
const SERVICE_WORKER_SCOPE = '/verify/app/';

export async function registerServiceWorker(startToken: string, clientId: string, debug: boolean) {
  if ('serviceWorker' in navigator) {
    if (debug) console.log('Service Worker support detected');

    const id = await getCurrentServiceWorkerId(debug);

    // if the service worker is already registered, we need to unregister it and reload the page to clear it
    if ((await unregisterExistingServiceWorker(debug, id)) && navigator.serviceWorker.controller) {
      window.location.replace(window.location.href.replace('/qr', ''));
      return;
    }

    try {
      const registration = await getRegistration(id, debug);
      await navigator.serviceWorker.ready;

      if (debug) console.log('sending message to service worker - app is loaded');
      registration.active?.postMessage({
        type: 'APP_LOADED',
        clientId,
        debug,
      });
    } catch (error) {
      const eventReport = eventFactory.createErrorEvent(
        'ServiceWorker registration failed',
        error?.toString() ?? 'Unknown error',
        'init',
      );
      sendEvent(startToken, eventReport);
      console.error('ServiceWorker registration failed:', error);
    }
  } else {
    console.warn('Service Worker support not detected');
  }
}

async function getCurrentServiceWorkerId(debug: boolean) {
  let etag = crypto.randomUUID().toString();
  try {
    const headResponse = await fetch(SERVICE_WORKER_PATH, { method: 'HEAD' });
    etag = headResponse.headers.get('etag') || headResponse.headers.get('Last-Modified') || etag;
    etag = etag.replace(/[^a-zA-Z0-9]/g, '');
  } catch (error) {
    if (debug) console.error('Error getting current etag:', error);
  }
  return etag;
}

async function getRegistration(id: string, debug: boolean) {
  let registration = await getActiveRegistration(id);
  if (!registration) {
    registration = await navigator.serviceWorker.register(`${SERVICE_WORKER_PATH}?debug=${debug === true}&id=${id}`, {
      scope: SERVICE_WORKER_SCOPE,
      updateViaCache: 'none',
    });
    if (debug) console.log('ServiceWorker registration successful:', registration.scope);
  } else {
    if (debug) console.log('ServiceWorker registration already exists');
  }
  return registration;
}

async function unregisterExistingServiceWorker(debug: boolean, id: string) {
  let unregistered = false;
  const existingRegistrations = await navigator.serviceWorker.getRegistrations();

  for (const registration of existingRegistrations) {
    if (registration.active?.scriptURL.includes(SERVICE_WORKER_PATH) && !registration.active?.scriptURL?.includes(id)) {
      await registration.unregister();
      unregistered = true;
      if (debug) console.log('Unregistered existing service worker:', registration.active?.scriptURL);
    }
  }
  return unregistered;
}

async function getActiveRegistration(id: string) {
  const existingRegistrations = await navigator.serviceWorker.getRegistrations();
  if (existingRegistrations.length === 0) return false;
  return existingRegistrations.find((registration) => registration.active?.scriptURL.includes(id));
}
